<template>
  <a href="" class="
  rounded-full p-3 mx-1 border-primary-600 whitespace-nowrap
  bg-primary-600
  text-primary-50 font-bold
  border-2 mb-5
  hover:bg-primary-50 hover:text-primary-600 hover:border-primary-600
  transition">{{ text }}</a>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name:  "Button"
};
</script>

<style scoped>

</style>
