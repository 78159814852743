<!-- eslint-disable-all -->
<template>
  <div class=" mx-auto text-center m-auto bg-secondary-600 dark:bg-secondary-800 w-full">
    <footer class="text-center text-white">
      <div class="p-6">
        <div class="text-center">
          <p class="flex justify-center items-center">
            <a href="https://twitter.com/intent/tweet?text=I%20just%20used%20social-share.link%20to%20create%20sharing%20links%20for%20my%20Newsletter.%20Try%20it%20out.%20It's%20free!%20&via=ben_rott&in_reply_to="
               class="mx-2 inline-block px-6 py-2 border-2
               border-secondary-50 text-secondary-50 font-medium text-xs
               leading-tight uppercase rounded-full
               hover:bg-secondary-50 hover:text-secondary-600
               focus:outline-none focus:ring-0
               transition duration-150 ease-in-out">
              Share on Twitter
            </a>
            <a href="mailto:sharesocial.link@benrott.de?subject=Feedback%20social-share.link"
               class="mx-2 inline-block px-6 py-2 border-2
               border-secondary-50 text-secondary-50 font-medium text-xs
               leading-tight uppercase rounded-full
               hover:bg-secondary-50 hover:text-secondary-600
               focus:outline-none focus:ring-0
               transition duration-150 ease-in-out">
              Send Feedback
            </a>
          </p>
        </div>
      </div>

      <div class="text-center bg-secondary-800 text-secondary-50 p-6">
        by <a class="text-white hover:underline transition duration-150 ease-in-out" href="https://ro.tt">Ben Rott</a>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Footer"
};
</script>
<style scoped>

</style>
