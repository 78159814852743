<template>
  <h4 class="text-2xl text-primary-600 font-black">{{ myTitle }}:</h4>

</template>

<script>
export default {
    name: "MyTitle",
    props: {
        myTitle: String, default: null,
    }
};
</script>

<style scoped>

</style>
