<template>
  <div class="text-center text-secondary-800 w-full md:w-1/2 lg:w-1/3" >
    <div class="m-4">
      <MyTitle myTitle="WhatsApp"></MyTitle>
      <Description>Requires WhatsApp or WhatsApp Web to be installed on the device</Description>
      <label for="wa-content">Message:</label><br>
      <textarea v-model="wacontent" class="text-primary-800
             border-b-2 border-b-slate-400 p-0.5 bg-secondary-200 mb-3
             w-full
             rounded-none
             placeholder:italic placeholder:text-slate-400
             focus:outline-none"
                placeholder="Your WhatsApp Message goes here.
                It also supports Emojis!🔥"
                id="wa-content" rows="5"></textarea><br>
      <code id="wa-code" class="break-words text-sm" style="hyphens: auto; word-wrap: break-word;">{{ wastring }}</code><br>
    </div>
    <Button @click="copy" href="#" text="Copy to clipboard"></Button>
    <Button :href="wastring" text="Test link"></Button>
  </div>
</template>

<script>
import Button from "@/components/Button";
import MyTitle from "@/components/MyTitle";
import Description from "@/components/Description";

export default {
    name: "WhatsApp",
    components: {Description, MyTitle, Button},
    data() {
        return {
            wacontent: ""
        };
    },
    computed: {
        wastring() {
            const eUc = encodeURIComponent(this.wacontent);
            return "whatsapp://send?text=" + eUc;
        }
    },
    methods: {
        copy() {
            this.$copyText(this.wastring)
                .then(() => {
                    console.log("copied!");
                })
                .catch(() => {
                    console.log("can't copy");
                });
        }
    }
};
</script>

<style scoped>

</style>
