<template>
  <div class="text-center text-secondary-800 w-full md:w-1/2 lg:w-1/3" >
    <div class="m-4">
      <MyTitle myTitle="Telegram"></MyTitle>
      <Description>Requires Telegram or Telegram Web to be installed on the device</Description>
      <label>Link:</label>
      <input v-model="tglink" type="text" id="link" required placeholder="You have to share a link if you want to use Telegrams Sharing"
             class="text-primary-800 bg-secondary-200
             border-b-2 border-b-slate-400 p-0.5 mb-3
             w-full
             rounded-none
             placeholder:italic placeholder:text-slate-400
             focus:outline-none">
      <div v-if="link_error" class="text-primary-600 font-bold">You have to add a link</div>
      <label for="tg-content">Message:</label><br>
      <textarea v-model="tgcontent" class="text-primary-800
             border-b-2 border-b-slate-400 p-0.5 bg-secondary-200 mb-3
             w-full
             rounded-none
             placeholder:italic placeholder:text-slate-400
             focus:outline-none"
                placeholder="Your Telegram Message goes here.
It also supports Emojis!🔥"
                id="tg-content" rows="5"></textarea><br>
      <code id="tg-code" class="break-words text-sm" style="hyphens: auto; word-wrap: break-word;">{{ tgstring }}</code><br>
    </div>
    <Button @click="copy" href="#" text="Copy to clipboard"></Button>
    <Button :href="tgstring" text="Test link"></Button>
  </div>
</template>

<script>
import Button from "@/components/Button";
import MyTitle from "@/components/MyTitle";
import Description from "@/components/Description";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Telegram",
    components: {Description, MyTitle, Button},
    data() {
        return {
            tgcontent: "",
            tglink: "",
        };
    },
    computed: {
        tgstring() {
            var eUc = encodeURIComponent(this.tgcontent);
            var link = encodeURIComponent(this.tglink);
            return "https://t.me/share/url?url=" + link + "&text=" + eUc;
        },
        link_error(){
            return this.tglink === "";
        }
    },
    methods: {
        copy() {
            this.$copyText(this.tgstring)
                .then(() => {
                    console.log("copied!");
                })
                .catch(() => {
                    console.log( "can't copy");
                });
        }

    }
};
</script>

<style scoped>

</style>
