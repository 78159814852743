<template>
  <div class="text-center text-secondary-800 w-full md:w-1/2 lg:w-1/3">
    <div class="m-4">
      <MyTitle myTitle="Email"></MyTitle>
      <Description>Requires an Email-Client to be installed on the device</Description>
      <label>Subject:</label>
      <input v-model="mailsubject" type="text" id="subject" placeholder="Your Subject"
             class="text-primary-800 bg-secondary-200
             border-b-2 border-b-slate-400 p-0.5 mb-3
             w-full
             rounded-none
             placeholder:italic placeholder:text-slate-400
             focus:outline-none">
      <label for="email-content">Mail-Content:</label><br>
      <textarea v-model="mailcontent" placeholder="Email Content goes here"
                class="text-primary-800 bg-secondary-200
             border-b-2 border-b-slate-400 p-0.5 mb-3
             w-full
             rounded-none
             placeholder:italic placeholder:text-slate-400
focus:outline-none"
                id="email-content" rows="5"></textarea><br>
      <code id="wa-code" class="break-words text-sm"
            style="hyphens: auto; word-wrap: break-word;">
        {{ mailstring }}</code>
    </div>
    <div class="m-5">
      <Button @click="copy" href="#" text="Copy to clipboard"></Button>
      <Button :href="mailstring" text="Test link"></Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import MyTitle from "@/components/MyTitle";
import Description from "@/components/Description";

export default {
    name: "EMail",
    components: {Description, MyTitle, Button},
    data() {
        return {
            mailcontent: "",
            mailsubject: ""
        };
    },
    computed: {
        mailstring() {
            var subject = encodeURIComponent(this.mailsubject);
            var content = encodeURIComponent(this.mailcontent);

            return "mailto:?subject=" + subject + "&body=" + content;
        }
    },
    methods: {
        copy() {
            this.$copyText(this.mailstring)
                .then(() => {
                    console.log("copied!");
                })
                .catch(() => {
                    console.log("can't copy");
                });
        }
    }
};
</script>

<style scoped>

</style>
