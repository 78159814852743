<template>
  <div class="text-center text-secondary-800 w-full md:w-1/2 lg:w-1/3">
    <div class="m-4">
      <MyTitle myTitle="Twitter"></MyTitle>
      <label for="tw-account" class="">Tag Account:</label><br>
      <input v-model="twvia" type="text" id="tw-account"
             class="text-primary-800 bg-secondary-200
             border-b-2 border-b-slate-400 p-0.5 mb-3
             w-full
             rounded-none
             placeholder:italic placeholder:text-slate-400
             focus:outline-none"
             placeholder="@ben_rott"><br>
      <label for="tw-reply_to" class="">Reply to: (Link to Tweet or Tweet-id)</label><br>
      <input v-model="tw_in_reply_to" type="text" id="tw-reply_to"
             class="text-primary-800 bg-secondary-200
             border-b-2 border-b-slate-400 p-0.5 mb-3
             w-full
             rounded-none
             placeholder:italic placeholder:text-slate-400
             focus:outline-none"
             placeholder="Tweet-ID"><br>
      <p v-if="this.tweet_id_error" class="text-primary-600">Please enter a Tweet URL or Tweet ID</p>
      <label for="tw-content">Tweet (max. 240 characters!):</label><br>
      <textarea v-model="twcontent" class="text-primary-800 bg-secondary-200
             border-b-2 border-b-slate-400 p-0.5 mb-3
             w-full
             rounded-none
             placeholder:italic placeholder:text-slate-400
             focus:outline-none"
                id="tw-content" rows="5"
                placeholder="Your Tweet goes here. You can mention via @twitter, use #Hashtags and include links!"
                maxlength="240"></textarea><br>
      <code id="tw-code" class="break-words text-sm" style="hyphens: auto; word-wrap: break-word;">{{ twstring }}</code><br>
    </div>
    <Button @click="copy" href="#" text="Copy to clipboard"></Button>
    <Button :href="twstring" text="Test link"></Button>
  </div>
</template>

<script>
import Button from "@/components/Button";
import MyTitle from "@/components/MyTitle";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Twitter",
    components: {MyTitle, Button},
    data() {
        return {
            twcontent: "",
            twvia: "",
            tw_in_reply_to: "",
        };
    },
    computed: {
        twstring() {
            const text = encodeURIComponent(this.twcontent);
            const via = encodeURIComponent(this.twvia.charAt(0) === "@".charAt(0) ? this.twvia.substring(1) : this.twvia);
            let tmp = "https://twitter.com/intent/tweet?text=" + text;
            if (via !== "") {
                tmp += "&via=" + via;
            }
            tmp += "&in_reply_to=" + this.tw_id;
            return tmp;
        },
        tw_id() {
            var url = this.tw_in_reply_to;
            if (!url.includes("/")) {
                //no / in URL -> ID was entered
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.tweet_id_error = false;
                return url;
            }
            //check if twitter.com in url!
            if (!url.includes("twitter.com")) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.tweet_id_error = true;
                return "";
            }
            //Remove / if at last position
            url = url.replace(/\/\s*$/, "");
            var id = url.substring(url.lastIndexOf("/") + 1);
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.tweet_id_error = false;
            return id;
        }

    },
    methods: {
        copy() {
            this.$copyText(this.twstring)
                .then(() => {
                    console.log("copied!");
                })
                .catch(() => {
                    console.log("can't copy");
                });
        }
    }
};
</script>

<style scoped>

</style>
