<template>
  <div class="text-xs mb-3"><slot></slot></div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Description"
};
</script>

<style scoped>

</style>
