<template>
  <div>
    <div
        class="p-5 mx-auto text-center m-auto bg-secondary-200 dark:bg-secondary-900 selection:bg-fuchsia-300 selection:text-fuchsia-900">
      <div class="max-w-7xl mx-auto">
        <h1 class="text-3xl font-display font-black text-center">Social-Share.link</h1>
        <h2 class="text-2xl font-display font-bold text-center">Create Links to share on Social Media</h2>
        <!--<h3 class="text-xl font-display font-bold text-center"></h3>-->
        <Menue></Menue>
        <div class="flex flex-row flex-wrap justify-center p-4" style="justify-content: center;">
          <EMail class="p-4" id="Email"></EMail>
          <Twitter class="p-4" id="Twitter"></Twitter>
          <WhatsApp class="p-4" id="Whatsapp"></WhatsApp>
          <Telegram class="p-4" id="Telegram"></Telegram>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import "./styles/app.css";
import EMail from "@/components/EMail";
import WhatsApp from "@/components/WhatsApp";
import Twitter from "@/components/Twitter";
import Footer from "@/components/Footer";
import Telegram from "@/components/Telegram";
import Menue from "@/components/Menue";


export default {
    name: "App",
    components: {
        Menue,
        Telegram,
        Footer,
        Twitter,
        WhatsApp,
        EMail,
    }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 300px;
}

body {
  background-color: rgb(14, 117, 107);
}
</style>
