<template>
  <nav class="flex items-center justify-between flex-wrap bg-teal-500 p-6">
    <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
      <div class="flex-grow xl:hidden">
        <a href="#Whatsapp" class="underline mt-4 inline-block mt-0 text-teal-200 hover:text-primary-600 transition mr-4">
          WhatsApp
        </a>
        <a href="#Email" class="underline mt-4 inline-block mt-0 text-teal-200 hover:text-primary-600 transition mr-4">
          Email
        </a>
        <a href="#Twitter" class="underline mt-4 inline-block mt-0 text-teal-200 hover:text-primary-600 transition mr-4">
          Twitter
        </a>
        <a href="#Telegram" class="underline mt-4 inline-block mt-0 text-teal-200 hover:text-primary-600 transition mr-4">
          Telegram
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Menue"
};
</script>

<style scoped>

</style>
